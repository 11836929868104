<template>
  <div id="app">
    <div id="nav">
      <div class="videoContainer">
        <video
          class="fullscreenVideo"
          id="bgVid"
          playsinline=""
          autoplay=""
          muted=""
          loop=""
        >
          <source
            src="https://www.wuzhengyun.com/static/video/1d4e82_d59c0584a3164e6a9ae4c4f889609281/720p/mp4/file.mp4"
            type="video/mp4"
          />
        </video>
        <div class="show_container">
          <div class="btns">
            <!--  <dv-border-box-10 v-for="(item, index) in routerList" :key="index">
              <router-link :to="item.path">{{ item.title }}</router-link>
            </dv-border-box-10> -->
            <div class="go_home" @click="goHome"></div>
          </div>
          <transition
            :duration="1000"
            mode="out-in"
            appear
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
            appear-active-class="animated zoomInDown"
          >
            <router-view />
          </transition>
        </div>

        <!-- <transition
          :duration="1000"
          mode="out-in"
          appear
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
          appear-active-class="animated zoomInDown"
        >
          <router-view />
        </transition> -->
      </div>
    </div>
    <!-- <dv-decoration-5 style="width: 100%; height: 50px" /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      routerList: [
        {
          path: "/",
          title: "Home",
        },
        /*  {
          path: "/about",
          title: "About",
        }, */
        {
          path: "/check",
          title: "Check",
        },
        {
          path: "/autoPlay",
          title: "AutoPlay",
        },
        {
          path: "/menu",
          title: "Menu",
        },
        /*  {
          path: "/process",
          title: "Process",
        }, */
      ],
      isRouterAlive: true,
      bacMusic: require("./assets/audio/bacMusic.mp3"),
      bacAudio: null,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  mounted() {},
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
.videoContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -100;
  .show_container {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.videoContainer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  background: rgba(25, 29, 34, 0.65);
  background-size: cover;
}
.fullscreenVideo {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.slide-fade {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 1;
}
.slide-fade-enter,
.slide-fade-leave-to {
  left: 0;
  top: 0;
  right: 0;
  opacity: 0;
  position: absolute;
  transform: translateY(100%);
}
.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s ease;
  transform: translateY(-100%);
  z-index: 100;
}

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  // padding: 30px;
  // background-color: burlywood;
  position: relative;
  z-index: 99;
  .router_container {
    position: relative;
    left: 0;
    top: 0;
  }
  .btns {
    width: 160px;
    text-align: center;
    position: absolute;
    .go_home {
      width: 160px;
      height: 160px;
      // border-radius: 50%;
      background-color: #fff;
      background: url("./assets/logo1.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 10px;
      margin-left: 10px;
    }
    // position: relative;
    // z-index: 99;
    .dv-border-box-10 {
      display: inline-block;
      width: 100px;
      height: 40px;
      color: #fff;
      margin-left: 5px;
      .border-box-content {
        padding: 10px 0;
      }
    }
  }
  a {
    font-weight: bold;
    color: #2c3e50;
    color: #fff;
    text-decoration: none;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
