import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dataV from "@jiaminghi/data-view";

import "swiper/swiper.min.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
import BaiduMap from "vue-baidu-map";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueMessage from "vue-message";
import animated from "animate.css"; // 引入
import "echarts";
import ECharts from "vue-echarts";

Vue.use(animated); // 全局注册
Vue.component("v-chart", ECharts);

/* import axios from "axios";
axios.defaults.baseURL = "https://8.131.77.34:8443";
  
Vue.prototype.$http = axios; */
Vue.use(dataV);

Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI);
Vue.use(VueMessage);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "TYSccIEp9TLkmD8UWnT8Ye1LEHIvnj8M",
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
