<template>
  <div class="home" :key="Math.random()">
    <div class="title">
      <img :src="textBac" alt="" />
    </div>
    <div class="btn">
      <div class="button">
        <div class="span" @click="toCheck"></div>
      </div>
    </div>
    <!-- <p class="tip">点击进入物证转换专区</p> -->
    <p class="tip" v-show="false">欢迎进入物证元宇宙</p>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      videoUrl: require("../assets/audio/welcome.mp3"),
      willEnterArea: require("../assets/audio/willEnterArea.mp3"),
      textBac: require("../assets/images/让世界更真实.png"),
      isTip: false,
      audioEl: null,
      willEl: null,
    };
  },
  mounted() {
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
    this.audioEl = new Audio(this.videoUrl);
    this.willEl = new Audio(this.willEnterArea);
    if (this.audioEl != null) {
      this.audioEl.play();
      this.isTip = true;
      this.audioEl.addEventListener("ended", () => {
        this.isTip = false;
      });
      // setTimeout(() => {}, 4000);
    }
  },
  beforeDestroy() {
    this.audioEl.pause();
  },
  methods: {
    /* 跳转到验证页 */
    toCheck() {
      this.willEl.play();
      this.willEl.addEventListener("ended", () => {
        console.log("结束了");
        this.$router.push("/check");
      });

      // console.log("点击了");
    },
    /*  playAudio() {
      this.$refs.audio.play();
    }, */
  },
};
</script>
<style scoped>
.home {
  height: 100%;
}
.title {
  font-size: 30px;
  color: #fff;
  width: 600px;
  height: 200px;
  margin: 100px auto;
  margin-bottom: 0;
}
.title img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tip {
  color: #fff;
  font-size: 40px;
}
.btn {
  width: 150px;
  margin: 20px auto;
  margin-bottom: 0;
}
@property --d {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0deg;
}
.button {
  width: 150px;
  aspect-ratio: 1;
  font-size: 30px;
  color: #fff;
  background: none;
  border: none;
  /* border-radius: 80px; */
  border-radius: 50%;
  position: relative;
  z-index: 0;
  transition: 0.3s;
  cursor: pointer;
  padding: 2px;
}
.button:before {
  content: "";
  position: absolute;
  inset: -8px;
  padding: 8px;
  /* border-radius: 80px; */
  border-radius: 50%;

  background: conic-gradient(
    from var(--d, 0deg),
    #21d4fd,
    #0000 30deg 120deg,
    #b721ff 150deg 180deg,
    #0000 210deg 300deg,
    #21d4fd 330deg
  );
  -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: intersect;
}
.button:after {
  content: "";
  position: absolute;
  inset: -100px;
  background: radial-gradient(80px at left 150px top 120px, #21d4fd 98%, #0000),
    radial-gradient(80px at right 150px bottom 120px, #b721ff 98%, #0000);
  filter: blur(60px);
  opacity: 0.5;
}

.button:before,
.button:after {
  transition: 0.5s, 99999s 99999s transform, 99999s 99999s --d;
}
.button:hover {
  box-shadow: 0 0 0 1px #666;
}

.button:hover:after {
  transform: rotate(3600deg);
  transition: 0.5s, 60s linear transform;
}
.button:hover:before {
  --d: 3600deg;
  transition: 0.5s, 60s linear --d;
}
.button:hover:before {
  background-color: #222;
}
.span {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* padding: 60px; */
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 99;
  background: url("../assets/logo2.jpg") no-repeat center center;
  background-size: 100% 100%;
}
</style>
