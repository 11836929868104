import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/check",
    name: "Check",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/check.vue"),
  },
  {
    path: "/autoPlay",
    name: "AutoPlay",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/autoPlay.vue"),
  },
  {
    path: "/menu",
    name: "Menu",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Menu.vue"),
  },
  {
    path: "/score",
    name: "Score",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Score.vue"),
  },
  {
    path: "/process",
    name: "Process",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Process.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
